import * as React from "react";

import Seo from "../components/seo";
import Layout from "../components/layout/Layout";
import ContentColumn from "../components/ui/ContentColumn";

const NotFoundPage = () => (
  <>
    <Seo title="Pagina non trovata" />
    <Layout>
      <ContentColumn>
        <h1>Pagina non trovata</h1>
        <p>La pagina a cui si è provato ad accedere non esiste.</p>
      </ContentColumn>
    </Layout>
  </>
);

export default NotFoundPage;
